@use "../../theme/theme";

//--------------------------------------------------------------------> DEBUGGING
/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid magenta;
    background: aqua;
} */
//--------------------------------------------------------------------> DEBUGGING
.Navigation {
    .Desktop {
        display: none;
    }
    .SideNav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        position: relative;
        z-index: 999 !important;

        .NaviUnclicker {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vmax;
            width: 0;
            background-color: rgba(0, 0, 0, 0);
            
            -webkit-transition: background-color 900ms ease-in;
            -moz-transition: background-color 900ms ease-in;
            -o-transition: background-color 900ms ease-in;
            -ms-transition: background-color 900ms ease-in;
            transition: width 900ms ease, background-color 900ms ease-in;
            
        }
        .FxIcoWhite {
            background: url("../../../asset/F-Light.png") no-repeat center center;
            background-size: contain;
            background-clip: padding-box;
            width: 3rem;
            height: 3rem;
        }
        .SideMenu {
            .MenuBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                //margin: 12px;
                cursor: pointer;
                overflow-x: hidden;

                .MenuBtnBurger {
                    width: 1.5rem;
                    height: 0.2rem;
                    background-color: theme.$deep;
                    border-radius: 5px;
                    transition: all 900ms ease-out;
                }
                .MenuBtnBurger::before,
                .MenuBtnBurger::after {
                    content: "";
                    position: absolute;
                    width: 1.5rem;
                    height: 0.2rem;
                    background-color: theme.$deep;
                    border-radius: 5px;
                    transition: all 900ms ease-out;
                }
                .MenuBtnBurger::before {
                    transform: translateY(-0.5rem);
                }
                .MenuBtnBurger::after {
                    transform: translateY(0.5rem);
                }

                &:hover {
                    .MenuBtnBurger,
                    .MenuBtnBurger::before,
                    .MenuBtnBurger::after {
                        background-color: theme.$fire;
                    }
                }
            }
            /* MENU BTN ANIMATION */
            .MenuBtn.Open .MenuBtnBurger {
                //transform: translateX(4rem);
                background-color: transparent;
            }
            .MenuBtn.Open .MenuBtnBurger::before {
                transform: rotate(45deg) /* translate(-2.8rem, 2.8rem) */;
                background-color: theme.$snow;
            }
            .MenuBtn.Open .MenuBtnBurger::after {
                transform: rotate(-45deg) /* translate(-2.8rem, -2.8rem) */;
                background-color: theme.$snow;
            }

            .SideBar {
                position: fixed;
                top: 0;
                right: 0;
                height: 100%;
                width: 0;
                border: none;
                border-left-width: 2px;
                border-left-style: outset;
                border-left-color: #767577;
                background-color: theme.$alfa-nght;
                overflow-x: hidden;
                transition: 900ms ease;

                .CloseMenuBtn {
                    width: 100%;
                    padding: 0.875rem;
                    background-color: transparent;
                    cursor: pointer;
                    text-align: center;

                    //transition: color 0.9s;
                    border-width: 2px;
                    border-style: outset;
                    border-color: #767577;
                    transition: 900ms ease-out;

                    .MenuBtn {
                        justify-content: center;
                        align-items: center;

                        .MenuBtnBurger::before,
                        .MenuBtnBurger::after {
                            background-color: theme.$shdw;
                        }
                    }
                    &:hover {
                        //border: 1px solid theme.$deep;
                        //transition: border 1.5s;

                        .MenuBtnBurger::before,
                        .MenuBtnBurger::after {
                            background-color: theme.$snow;
                        }
                    }
                    .MenuBtn.Close .MenuBtnBurger {
                        background-color: transparent;
                    }
                    .MenuBtn.Close .MenuBtnBurger::before {
                        transform: rotate(45deg) translate(-0.5rem, 0.5rem);
                    }
                    .MenuBtn.Close .MenuBtnBurger::after {
                        transform: rotate(-45deg) translate(-0.5rem, -0.5rem);
                    }
                }
                a {
                    display: block;
                    width: 100%;
                    padding: 0.8rem 0.25rem;
                    font-size: 1rem;
                    text-align: center;
                    text-decoration: none;
                    color: theme.$fnt-shdw;
                    background-color: theme.$alfa-deep;
                    // white-space: nowrap;
                    //transition: 1.9s;
                    transition: color 900ms;

                    &:hover {
                        color: theme.$fnt-snow;
                    }
                }
                .NavLinkAbout {
                    white-space: nowrap;
                }
                .LangBtn {
                    display: flex;
                    width: 100%;
                    border: none;
                    cursor: pointer;
                    padding: 0.8rem 0.25rem;
                    background-color: theme.$alfa-deep;
                    text-align: center;
                    justify-content: center;

                    .LangFlag {
                        width: 2rem;
                    }
                }
                .active {
                    color: theme.$fnt-sand !important;
                    text-decoration: underline;
                    text-decoration-color: theme.$fnt-snow;
                }
            }
        }
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-0-250px
@include theme.wdth-lvl-0-250px {
    .Navigation {
        .SideNav {
            .SideMenu {
                .SideBar {
                    .CloseMenuBtn {
                        text-align: left;
                        
                        .MenuBtn.Close .MenuBtnBurger::before {
                            transform: rotate(45deg) /* translate(-0.5rem, 0.5rem) */;
                        }
                        .MenuBtn.Close .MenuBtnBurger::after {
                            transform: rotate(-45deg) /* translate(-0.5rem, -0.5rem) */;
                        }
                    }
                    a {
                        padding: 0.8rem 0.5rem;
                        text-align: left;
                        white-space: nowrap;
                    }
                    .LangBtn {
                        padding: 0.8rem 0.5rem;
                        text-align: left;
                        justify-content: start;
                    }
                }
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-1-320px
@include theme.wdth-lvl-1-320px {
    .Navigation {
        .SideNav {
            .SideMenu {
                .SideBar {
                    
                    a {
                        padding: 0.8rem 1rem;
                        font-size: 1.1rem;
                    }
                    .LangBtn {
                        padding: 0.8rem 1rem;
                    }
                }
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-2-375px
@include theme.wdth-lvl-2-375px {
    .Navigation {
        .SideNav {
            .SideMenu {
                .SideBar {
                    
                    a {
                        padding: 0.8rem 2rem;
                        font-size: 1.2rem;
                    }
                    .LangBtn {
                        padding: 0.8rem 2rem;
                    }
                }
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-4-768px
@include theme.wdth-lvl-4-768px {
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .Navigation {
        //width: 90%;
        margin: 0 auto;
        z-index: 999 !important;

        .Mobile {
            display: none;
        }
        .Desktop {
            display: block;
            margin: 0 auto;
        }
        .NavBar {
            display: flex;
            justify-content: flex-end;
            text-align: center;
            align-items: center;
            padding: 0.8rem 1rem;

            a {
                width: auto;
                padding: 0.8rem 1.1rem;
                font-size: 1.2rem;
                text-decoration: none;
                color: theme.$fnt-deep;
                //text-shadow: -0.04rem 0.03rem 0 theme.$fnt-deep-shdw;

                &:hover {
                    animation: 500ms multicolor;
                    color: theme.$fire;
                    //text-shadow: 0.03rem -0.03rem 0 theme.$fnt-hov-shdw;
                    //-webkit-transition: text-shadow 1.1s;
                    //-moz-transition: text-shadow 1.1s;
                    //-o-transition: text-shadow 1.1s;
                    transition: color 500ms/* , text-shadow 1.1s */;
                }

                @keyframes multicolor {
                    0% {
                        color: theme.$deep;
                    }
                    20% {
                        color: theme.$deep;
                    }
                    65% {
                        color: theme.$fnt-fire;
                    }
                    100% {
                        color: theme.$fire;
                    }
                }
            }
            .active {
                color: theme.$fire !important;
                //text-shadow: -0.04rem 0.03rem 0 theme.$fnt-fire-shdw;
                border-bottom: 1px solid theme.$fire;

                &:hover {
                    //text-shadow: -0.01rem 0.01rem 0 theme.$fnt-fire-shdw;
                    color: theme.$fnt-fire !important;
                    border-bottom: 1px solid theme.$fnt-fire;
                }
            }
            .LangBtn {
                display: flex;
                width: 2.7rem;
                margin: 0 1rem;
                //padding: 0.025rem;
                //background-color: theme.$shdw;
                //border: 1px solid theme.$deep;
                cursor: pointer;
                /*transition: border-color 500ms , border-color 1s, padding 0.4s */;

                .LangFlag {
                    width: 100%;
                    //background-color: theme.$deep;
                    //padding: 0.1rem;
                    //transition: background-color 1.5s, width 0.4s, padding 0.4s;
                }

                /*&:hover {
                    //padding: 0.13rem;
                    border-color: theme.$fire;
                    //border: 1px solid theme.$fire;
                    transition: border-color 500ms;

                     .LangFlag {
                        background-color: theme.$lead;
                    } 
                }*/
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-6-1440px
@include theme.wdth-lvl-6-1440px {
}
