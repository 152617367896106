// Custom Properties
//PALETTE
/* :root {
    //PALETTE
    //MAIN-THEME
    --snow: #ffffff;
    --sand: #f4e9cd;
    --fire: #d17b0f;
    --lead: #608724;
    --deep: #0e4b01;
    //NAVIGATION
    //MOBILE
    --blk-a: rgba(0, 0, 0, 0.84);
    --grn-a: #001800e7;
    --grn-hv: #003b01;
    //DESKTOP
    --gry-ft: rgb(128, 128, 128);
    --gry-ft-actv: rgb(185, 185, 185);
    --gry-ft-hv: rgb(216, 216, 216);
    --gry-hv: rgb(247, 247, 247);
    //FONT
    --bk-ft: #000;
    --wht-ft: #fff;
    //SIDE-NAV WIDTH
    --nav-wdth: 50%;
} */
//SASS
//PALETTE
//MAIN-THEME
$deep: rgb(14, 75, 1); // #0e4b01
$fire: rgb(167, 92, 0); // #a75c00 #d17b0f
$lead: rgb(96, 135, 36); // #608724
$lght: rgb(255, 140, 0); // #a75c00 #d17b0f
$sand: rgb(244, 233, 205); // #f4e9cd
$snow: rgb(255, 255, 255); // #ffffff
$nght: rgb(0, 0, 0); // #000000
$shdw: rgb(124, 124, 124); // #7c7c7c
$bckg: rgb(3, 20, 0);
//NAVIGATION
$alfa-deep: rgba(0, 24, 0, 0.975);
$alfa-snow: rgba(255, 255, 255, 0.75);
$alfa-nght: rgba(0, 0, 0, 0.84);
$hov-deep: rgb(0, 59, 1);
//FONT
$fnt-snow: rgb(255, 255, 255);
$fnt-sand: rgb(255, 239, 200);
$fnt-fire: rgb(92, 45, 0); //128, 70, 0
$fnt-lead: rgb(113, 188, 0);
$fnt-deep: rgb(17, 90, 0);
$fnt-fire-shdw: rgb(69, 40, 27); //124, 75, 16
$fnt-deep-shdw: rgb(23, 81, 8);
$fnt-nght: rgb(0, 0, 0);
$fnt-shdw: rgb(160, 160, 160); 
$fnt-hov-shdw: rgb(135, 74, 0);
$hov-shdw: rgb(247, 247, 247);
//SIDE-NAV WIDTH
$nav-wdth: 50%;
//MIN-WIDTH LEVELS
/* $lvl-0-Mob-S: 250px;  // 250 - 320px
$lvl-1-Mob-M: 320px;  // 320 - 375px
$lvl-2-Mob-L: 375px;  // 375 - 425px
$lvl-3-Tab: 425px;    // 425 - 768px
$lvl-4-Lap: 768px;    // 768 - 1024px
$lvl-5-Lap-L: 1024px; // 1024 - 1440px
$lvl-6-4K: 1440px;    // 1440 - 2560px */
$wdth-lvl-0-250px: 250px;  // 250 - 320px
$wdth-lvl-1-320px: 320px;  // 320 - 375px
$wdth-lvl-2-375px: 375px;  // 375 - 425px
$wdth-lvl-3-425px: 425px;    // 425 - 768px
$wdth-lvl-4-768px: 768px;    // 768 - 1024px
$wdth-lvl-5-1024px: 1024px; // 1024 - 1440px
$wdth-lvl-6-1440px: 1440px;    // 1440 - 2560px
/* //MIN-WIDTH LEVELS
$lvl-1: 540px;
$lvl-2: 875px;
$lvl-3: 1000px; */
/* @mixin wdth-lvl-1-Mob-M {
    @media only screen and (min-width: 320px) {
        @content;
    }
}
@mixin wdth-lvl-2-Mob-L {
    @media only screen and (min-width: 375px) {
        @content;
    }
}
@mixin wdth-lvl-3-Tab {
    @media only screen and (min-width: 425px) {
        @content;
    }
}
@mixin wdth-lvl-4-Lap {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
@mixin wdth-lvl-5-Lap-L {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}
@mixin wdth-lvl-6-4K {
    @media only screen and (min-width: 1440px) {
        @content;
    }
} */
// -----------------------------------------------
@mixin wdth-lvl-0-250px {
    @media only screen and (min-width: 250px) {
        @content;
    }
}
@mixin wdth-lvl-1-320px {
    @media only screen and (min-width: 320px) {
        @content;
    }
}
@mixin wdth-lvl-2-375px {
    @media only screen and (min-width: 375px) {
        @content;
    }
}
@mixin wdth-lvl-3-425px {
    @media only screen and (min-width: 425px) {
        @content;
    }
}
@mixin wdth-lvl-4-768px {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
@mixin wdth-lvl-5-1024px {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}
@mixin wdth-lvl-6-1440px {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}
// -------------------------
// Z-INDEX
// 999 -----> LOADING OVERLAY
// 
// 998 -> BOOK CONFIRM
// 997 -> BOOK MODAL
// 996 -> APP NEW SESSION
// 995 -> APP EDIT SESSION
// 
// 556 -> CONFIRM OVERLAY
// 
// 555 -----> NAVIGATION
// 
// 2 ->
// 1 ->
// 
// 0 -----> BACKGROUND WHITE / BLACK