@use './theme';
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

//--------------------------------------------------------------------> DEBUGGING
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* border: 1px solid magenta; */
    /* background: aqua; */
}
//--------------------------------------------------------------------> DEBUGGING
// SCROLLBAR
html,
body {
    height: 100%;
    /* width */
    ::-webkit-scrollbar {
        width: 15px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background-color: #0e4b01;
        //background-color: #f4e9cd;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: #608724;
        //background-color: #0e4b01;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #ff8c00;
        //background-color: #608724;
    }
}
// FONT
body {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: "Montserrat", sans-serif;
}
// BACKGROUND
body {
    // LIGHT THEME:
    background-color: theme.$snow;
    // DARK THEME:
    /* background-color: rgb(32, 14, 3); */
}