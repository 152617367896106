@use "../../../components/theme/_theme";

.LogoSection {
    text-align: center;
    padding: 1.3rem 0.8rem;
    padding-top: 0;
    margin-bottom: 2rem;
    //height: 45vh;

    .LogoArea {
        .Logo {
            width: 45%;
        }
        #FxA {
            /* background: url("../../../../asset/FxA.png") no-repeat center center;
            background-size: contain;
            background-clip: padding-box; */
            pointer-events: none;
            margin-top: -1.5rem;
        }
        #FxWideA {
            /* background: url("../../../../asset/FxWideA.png") no-repeat center center;
            background-size: contain;
            background-clip: padding-box; */
            pointer-events: none;
            display: none;
        }
    }

    .Sentence {
        .SentenceHeader,
        .SentenceParagraph {
            position: relative;
            z-index: 3;
            display: block;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
        }
        .SentenceHeader {
            font-size: 1.6rem;
            margin-block-start: 0;
            margin-block-end: 1rem;
            font-weight: 400;
            color: theme.$deep;
        }
        .SentenceParagraph {
            font-size: 1.2rem;
            margin-block-start: 1.5rem;
            margin-block-end: 1.5rem;
            line-height: 1.6rem;
            letter-spacing: 0.5rem;
            text-transform: uppercase;
            color: theme.$shdw;
        }
    }
}
//------------------------------------------------------------------------------------------------------------------> ANIMATIONS
.LogoSection {
    .Logo {
        animation: showUpLogo 4s forwards;
    }

    .SentenceHeader {
        animation: showUpHeader 8s forwards;
    }

    .SentenceParagraph {
        animation: showUpParagraph 10s forwards;
    }
}

@keyframes showUpLogo {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes showUpHeader {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes showUpParagraph {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .LogoSection {
        .LogoArea {
            #FxA {
                width: 35%;
            }
        }
        .Sentence {
            .SentenceHeader {
                font-size: 2rem;
                margin: 0;
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-4-768px
@include theme.wdth-lvl-4-768px {
    .LogoSection {
        //height: 50vh;
        .LogoArea {
            #FxA {
                width: 30%;
            }
        }
        .Sentence {
            .SentenceHeader {
                font-size: 2.1rem;
                margin: 0;
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .ShowOnDesktop {
        display: block;
        margin: 0 auto 0 auto;
    }

    .HideOnDesktop {
        display: none;
    }

    .LogoSection {
        padding: 1.2rem 0;
        z-index: -1;
        //height: 60vh;

        .LogoArea {
            #FxWideA {
                display: block;
                width: 35%;
                margin-top: -3rem;
                padding-right: 1.75rem;
                z-index: -1;
            }
        }

        .Sentence {
            padding: 1rem 0;

            .SentenceHeader {
                font-size: 2.5rem;
                letter-spacing: 0.1rem;
            }

            .SentenceParagraph {
                font-size: 1.5rem;
                letter-spacing: 0.5rem;
                text-transform: uppercase;
                color: theme.$shdw;
                margin-top: 3rem;
            }
        }
    }
}
