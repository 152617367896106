@use "../../../components/theme/_theme";

.ContactHeader {
    text-align: center;
    padding: 4rem 2rem;
    margin-bottom: 1.5rem;

    .ContactSubhead {
        text-transform: uppercase;
        font-weight: bold;
        color: gray;
        letter-spacing: 0.3rem;
        margin-bottom: 1.5rem;
    }

    .ContactHeaderH1 {
        font-size: 2rem;
        line-height: 1.3rem;
        margin-top: 1rem;
        color: theme.$deep;
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .ContactHeader {
        padding: 1rem 4rem;

        .ContactSubhead{
            margin-bottom: 1.5rem;
        }

        .ContactHeaderH1 {
            font-size: 2.2rem;
            padding: .8rem;
            margin: 0rem;
        }
    }
}