@use "../../../components/theme/_theme";

.AvatarSection {
    background-image: url("../../../asset/WFAvatar.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 20rem;
    color: theme.$fnt-snow;
    text-align: left;
    border-bottom: 2px solid theme.$lead;

    .AvatarHeader {
        //margin: auto 0;
        background: theme.$snow;
        color: theme.$deep;
        padding: 0.4rem 0.8rem;
        border-bottom: 2px solid theme.$deep;

        .AvatarName {
            font-size: 1.6rem;
            font-weight: 400;
        }
    }

    .AvatarContainer {
        //margin: auto 1rem;
        //overflow: hidden;
        //margin: auto 0;
        //padding: 0 1.5rem 0 0.8rem;
        padding: 0.4rem 0.5rem;

        .AvatarOccupation {
            padding: 0.3rem;
            border-bottom: thin solid theme.$snow;
            background-color: rgba(51, 51, 51, 0.4);
            font-weight: 600;
            font-size: 1.3rem;
            text-shadow: 2px 2px 1px #000000;
        }

        .AvatarDalailama {
            //margin-top: 7rem;
            width: fit-content;
            align-items: center;
            margin: 15rem auto 0;
            padding: 0.5rem 0.9rem;
            background-color: rgba(51, 51, 51, 0.6);
            border: thin solid theme.$snow;
            border-radius: 10px;

            .AvatarDalailamaP {
                text-shadow: 2px 2px 1px #000000;
                padding-top: 0.4rem;
                font-weight: 500;
            }
        }
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .AvatarSection {
        //height: 47vh;
        //min-height: 19rem;

        .AvatarHeader {
            .AvatarName {
                font-size: 1.9rem;
            }
        }

        .AvatarContainer {
            //padding: 0 1.5rem 0 0.8rem;

            .AvatarOccupation {
                font-size: 1.4rem;
            }

            .AvatarDalailama {
                //margin-top: 19vh;
                //margin-bottom: 1rem;

                .AvatarDalailamaP {
                    //padding-top: 0.4rem;
                    font-size: 1.1rem;
                }
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-4-768px
@include theme.wdth-lvl-4-768px {
    .AvatarSection {
        height: 55vh;

        /* .AvatarHeader {
            .AvatarName {
                //font-size: 1.9rem;
            }
        } */

        .AvatarContainer {
            //padding: 0 1.5rem 0 0.8rem;

            /* .AvatarOccupation {
                //font-size: 1.4rem;
            } */

            .AvatarDalailama {
                margin: 1rem auto 0 0;
                //margin-bottom: 1rem;

                /* .AvatarDalailamaP {
                    //padding-top: 0.4rem;
                    //font-size: 1.2rem;
                } */
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .AvatarSection {
        height: 59vh;

        .AvatarHeader,
        .AvatarContainer {
            width: 90%;
            margin: 0 3.2rem;
            justify-content: flex-start;
            padding: 0 2.1rem;
        }

        .AvatarHeader {
            width: 100%;
            margin: 0;
            padding: 0 5.3rem;

            .AvatarName {
                font-size: 2.2rem;
            }
        }

        .AvatarContainer {
            padding: 0 2.1rem;

            .AvatarOccupation {
                padding: 0.6rem 0.3rem;
                font-size: 1.9rem;
            }
            .AvatarDalailama {
                .AvatarDalailamaP {
                    padding: 0.25rem 0;
                    font-size: 1.2rem;
                }
            }
        }
    }
}