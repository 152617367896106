@use "../../../components/theme/_theme";

.ServiceSection {
    width: 100%;
    padding: 3rem 0 2rem;
    text-align: center;
    border-top-left-radius: 40px;
    background: theme.$deep;
    color: theme.$snow;
    transform: skewY(-2deg);

    &:before {
        content: "";
        width: 40px;
        height: 40px;
        background-color: theme.$deep;
        position: absolute;
        top: -39px;
        right: 0;
        z-index: -2;
    }
    &:after {
        content: "";
        width: 80px;
        height: 80px;
        background-color: theme.$snow;
        position: absolute;
        top: -80px;
        right: 0;
        border-radius: 50%;
        z-index: -1;
    }

    .ServicesList {
        list-style-type: none;
        transform: skewY(2deg);
        margin-bottom: -5rem;

        li {
            margin-bottom: 3rem;
            padding: 0 0.5rem;

            .TreatmentList {
                font-size: 1.7rem;
                font-weight: 500;
            }
            .Treatment {
                font-size: 1.2rem;
            }
            .Service {
                font-size: 1.5rem;
                font-weight: 600;
            }
            .Price {
                font-size: 1.3rem;
                font-weight: 600;
            }
            .OnlineSessions {
                font-size: 1.3rem;
                font-weight: 600;
            }
        }
    }
}
// ---------------------------------------------------------------------------------> ANIMATIONS
.ServiceSection {
    .ServicesList {
        li {
            .TreatmentList {
                animation: showUpServiceHeader 11s forwards;
            }
            .Treatment {
                animation: showUpServiceParagraph 12s forwards;
            }
        }
    }
}

@keyframes showUpServiceHeader {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes showUpServiceParagraph {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .ServiceSection {
        margin: 0;

        .ServicesList {
            justify-content: space-between;
            margin: 2% 1% 2% 1%;
            margin-bottom: -5rem;
            align-items: center center;
            padding: 0;

            li {
                margin: 0 1rem  3rem;
                padding: 0;

                .TreatmentList {
                    font-size: 1.8rem;
                }
                .Treatment {
                    padding: 0;
                    font-size: 1.2rem;
                }
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-4-768px
@include theme.wdth-lvl-4-768px {
    .ServiceSection {
        margin: 0;

        .ServicesList {
            margin: 4% 0;
            margin-bottom: -5rem;
            list-style-type: none;

            li {
                text-decoration: none;
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .ServiceSection {
        margin-top: 3rem;
        .ServicesList {
            justify-content: space-around;
            margin: 2% 0;
            margin-bottom: -5rem;

            li {
                text-decoration: dotted;

                .Treatment {
                    font-size: 1.4rem;
                    margin-top: 0.5rem;
                }
            }
        }
    }
}