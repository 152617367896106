@use "../../../theme/_theme";

.EQ {
    text-align: center;
    padding: 1.2rem 0.8rem;
    background: theme.$deep;
    border-bottom: 1px solid theme.$lead;
    padding: 4rem 0 2rem;
    margin-top: -3%;
    display: grid;

    EQframe {
        width: 100%;
        border: none;
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .EQ {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .EQContainer {
            width: 100%;
            height: 100%;
        }
    }
}