@use "../../../theme/_theme";

.ContactUs {
    display: inline;
    text-align: center;
    max-width: 15rem;
    margin: 2rem 0;

    .ContactUsHead {
        color: theme.$deep;
        margin: 1rem;
    }

    .ContactUsList {
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin-bottom: 1rem;

        .ContactUsListItem {
            .ContactUsLink {
                text-decoration: none;
                margin: 0.5rem;

                .EmailGrn {
                    width: 2rem;
                }
                .FBGrn {
                    width: 1.9rem;
                }
                .PhoneGrn {
                    width: 2.1rem;
                }
                .MapsGrn {
                    width: 2rem;
                }
            }
        }
    }
}
