@use "../../../theme/_theme";

.footerSection {
    background: theme.$deep;
    width: 100%;
    color: theme.$snow;
    padding: 3rem 0;
    border-top-right-radius: 40px;
    transform: skewY(2deg);
    z-index: 0 !important;

    &:before {
        content: "";
        width: 40px;
        height: 40px;
        background-color: theme.$deep;
        position: absolute;
        top: -39px;
        left: 0;
        z-index: -2;
    }

    &:after {
        content: "";
        width: 80px;
        height: 80px;
        background-color: theme.$snow;
        position: absolute;
        top: -80px;
        left: 0;
        border-radius: 50%;
        z-index: -1;
    }

    .footerTop {
        text-align: center;
        padding: 1.2rem 0;
        transform: skewY(-2deg);

        .ContactToBookLink {
            color: theme.$snow;
            text-decoration: none;
            .MapsNav {
                width: 2.75rem;
                margin: -1.5rem 0 1rem;
            }
        }

        .footerAddress {
            font-size: 1.25rem;
            color: theme.$snow;
        }

        .ContactBottom{
            margin-bottom: 1.5rem;
        }

        .footerLinks {
            li {
                p {
                    color: theme.$shdw;
                }
            }
        }
    }

    .footerLogo {
        height: 7rem;
        pointer-events: none;
    }

    ul {
        list-style-type: none;

        .footerLinks {
            margin: 0 auto;

            li {
                display: inline-block;

                a {
                    color: theme.$shdw;
                    font-size: 0.8rem;
                }
            }
        }
    }
}

.footerBottom {
    background-color: theme.$deep;
    color: theme.$shdw;
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: -2%;
    padding-bottom: 10rem;

    p {
        margin: 0.8rem 0;
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .footerSection {
        .footerTop {
            padding: 1.2rem 0;
        }
    }
}
