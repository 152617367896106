@use "../../../theme/_theme";

.ContactToBook {
    text-align: center;
    width: 100%;
    background: theme.$snow;
    padding: 2rem 0 1rem;
    color: theme.$deep;

    .ContactToBookHead {
        color: theme.$deep;
        margin: 0.5rem 1rem 2rem;
    }

    .ContactToBookList {
        list-style-type: none;

        .ContactToBookListItem {
            margin-bottom: 3rem;
            padding: 0 0.5rem;

            .ContactToBookText {
                font-size: 1.2rem;
            }

            .ContactToBookLink {
                color: theme.$deep;
                text-decoration: none;

                .Email {
                    width: 2rem;
                }

                .FB {
                    width: 2rem;
                }

                .Phone {
                    width: 2.5rem;
                }
                .MapsNavi {
                    width: 2rem;
                }
            }
        }
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .ContactToBook {
        margin: 0;
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-4-768px
@include theme.wdth-lvl-4-768px {
    .ContactToBook {
        margin: 0;

        .ContactToBookList {
            justify-content: center;
            justify-content: space-between;
            align-items: center;
            list-style-type: none;

            .ContactToBookListItem {
                text-decoration: none;
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .ContactToBook {
        .ContactToBookList {
            .ContactToBookListItem {
                .ContactToBookText {
                    font-size: 1.3rem;
                    margin-top: 0.5rem;
                }
            }
        }
    }
}