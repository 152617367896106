@use "../../../components/theme/_theme";

.CvSection {
    text-align: center;
    background-color: theme.$sand;
    min-height: 25rem;
    color: theme.$deep;
    padding: 0;
    border-bottom: 1px solid theme.$lead;

    .CvHead {
        background-color: theme.$deep;
        padding: 0.4rem 0.8rem;

        .CvWelcome {
            font-size: 1.6rem;
            color: theme.$snow;
        }
    }

    .CvContent {
        padding-bottom: 2rem;

        p {
            //letter-spacing: 0.5rem;
            text-transform: uppercase;
            margin: 2rem auto 0;
            font-size: 1rem;
        }

        h2 {
            //letter-spacing: 0.5rem;
            text-transform: uppercase;
            margin: 2rem auto 0;
            font-size: 1.6rem;
        }
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .CvSection {
        .CvHead {
            .CvWelcome {
                font-size: 1.9rem;
            }
        }
        .CvContent {
            p {
                font-size: 1.2rem;
            }

            h2 {
                font-size: 1.8rem;
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .CvSection {
        .CvHead {
            padding: 0.5rem 0;

            .CvWelcome {
                font-size: 2.2rem;
            }
        }
        .CvContent {
            p {
                font-size: 1.4rem;
            }

            h2 {
                font-size: 2.1rem;
            }
        }
    }
}
