@use "../../../components/theme/_theme";

.ContactInfo {
    text-align: center;
    width: 100%;
    background: theme.$deep;
    padding: 4rem 0 2rem;
    color: theme.$snow;
    border-top-left-radius: 40px;
    transform: skewY(-2deg);

    &:before {
        content: "";
        width: 40px;
        height: 40px;
        background-color: theme.$deep;
        position: absolute;
        top: -39px;
        right: 0;
        z-index: -2;
    }

    &:after {
        content: "";
        width: 80px;
        height: 80px;
        background-color: white;
        position: absolute;
        top: -80px;
        right: 0;
        border-radius: 50%;
        z-index: -1;
    }

    .ContactInfoList {
        list-style-type: none;
        transform: skewY(2deg);
        margin-bottom: -5rem;

        .ContactInfoListItem {
            margin-bottom: 3rem;
            padding: 0 0.5rem;

            .ContactInfoLink {
                color: theme.$snow;
                text-decoration: none;

                .Email {
                    width: 2rem;
                }

                .FB {
                    width: 2.5rem;
                }

                .Phone {
                    width: 2rem;
                }
                .MapsNavi {
                    width: 2rem;
                }
            }
        }
    }
}
//------------------------------------------------------------------------------------------------------------------> MEDIA-QUERY
//--------------------------------------------------------------------------------> WIDTH LEVEL-3-425px
@include theme.wdth-lvl-3-425px {
    .ContactInfo {
        margin: 0;
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-4-768px
@include theme.wdth-lvl-4-768px {
    .ContactInfo {
        margin: 0;

        .ContactInfoList {
            justify-content: center;
            justify-content: space-between;
            align-items: center;
            list-style-type: none;

            .ContactInfoListItem {
                text-decoration: none;
            }
        }
    }
}
//--------------------------------------------------------------------------------> WIDTH LEVEL-5-1024px
@include theme.wdth-lvl-5-1024px {
    .ContactInfo {
        .ContactInfoList {
            .ContactInfoListItem {
                .ContactInfoText {
                    font-size: 1.2rem;
                    margin-top: 0.5rem;
                }
            }
        }
    }
}